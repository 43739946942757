import React from "react"

import Layout from "../../components/js/layout"
import SEO from "../../components/js/seo"
import LectureNotionPage from "../../components/js/lectures/lecture-notion-page"

import notionData from "./../../data/lectures/notion-lectures.json"
const LectureId = "java"

const lectureData = notionData.lectures
  .filter(({id}) => id === LectureId)[0];


class Lecture extends React.Component {

  render () {

    return (
      <Layout page="lectures">
        <SEO title={lectureData.title}/>
        <LectureNotionPage lecture={lectureData}></LectureNotionPage>
      </Layout>
    )
  }
}

export default Lecture